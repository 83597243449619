export const setItemLS = (key, value) => {
  if (typeof window !== 'undefined') {
    localStorage?.setItem(key, value);
  }
};

export const getItemLS = (key) => {
  if (typeof window !== 'undefined') {
    return localStorage?.getItem(key);
  }
};

export const removeItemLS = (key) => {
  if (typeof window !== 'undefined') {
    localStorage?.removeItem(key);
  }
};
